import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        users: [],
        roles: [],
        userIdCount: 0,
        roleIdCount: 0
    },
    getters: {
        getUsers(state) {
            return state.users;
        },
        getRoles(state) {
            return state.roles;
        }
    },
    mutations: {
        createUser(state, user) {
            user.id = state.userIdCount;
            user.role = user?.role?.id;
            state.users.push(user);
        },
        incrementUserId(state) {
            state.userIdCount += 1;
        },
        updateUser(state, updatedUser) {
            const userIndex = state.users.findIndex(user => user.id === updatedUser.id);
            state.users[userIndex].name = updatedUser.name;
            state.users[userIndex].email = updatedUser.email;
            state.users[userIndex].mobile = updatedUser.mobile;
            state.users[userIndex].country = updatedUser.country;
            state.users[userIndex].role = updatedUser?.role?.id;
        },
        deleteUser(state, user_id) {
            state.users.splice(state.users.find(user => user.id === user_id), 1)
        },


        createRole(state, role) {
            role.id = state.roleIdCount;
            role.read = false;
            role.write = false;
            role.modify = false;
            state.roles.push(role);
        },
        incrementRoleId(state) {
            state.roleIdCount += 1;
        },
        updateRole(state, updatedRole) {
            const roleIndex = state.roles.findIndex(role => role.id === updatedRole.id);
            state.roles[roleIndex].name = updatedRole.name;
            state.roles[roleIndex].read = updatedRole.read;
            state.roles[roleIndex].write = updatedRole.write;
            state.roles[roleIndex].modify = updatedRole.modify;
        },
        deleteRole(state, roleId) {
            state.roles.splice(state.roles.find(role => role.id === roleId), 1);
        }

    },
    actions: {},
    modules: {}
})
