<template>
	<v-container>
		<v-card class="elevation-1">
			<v-card-title>
				<h3>Users</h3>
				<v-spacer></v-spacer>
				<v-dialog v-model="dialog" persistent max-width="600px">
					<v-card>
						<v-card-title>
							<span class="text-h5" v-if="currentAction === 'create'">Create User</span>
							<span class="text-h5" v-if="currentAction === 'update'">Update User</span>
						</v-card-title>
						<v-card-text>
							<v-container>
								<v-row no-gutters>
									<v-col cols="12">
										<v-text-field label="Full Name*" v-model="user.name" dense outlined required></v-text-field>
									</v-col>
								</v-row>
								<v-row no-gutters>
									<v-col cols="12">
										<v-text-field label="Email*" type="email" v-model="user.email" dense outlined required></v-text-field>
									</v-col>
								</v-row>
                                <v-row no-gutters>
									<v-col cols="12">
										<v-text-field label="Mobile*" type="email" v-model="user.mobile" dense outlined required></v-text-field>
									</v-col>
								</v-row>
								<v-row no-gutters>
									<v-col cols="12">
										<v-text-field label="Country*" type="text" v-model="user.country" dense outlined required></v-text-field>
									</v-col>
									<v-col cols="12" v-if="roles">
										<v-select :items="roles" label="Select a Role*" item-text="name" v-model="user.role" return-object dense outlined required></v-select>
									</v-col>
								</v-row>
							</v-container>
						</v-card-text>
						<v-card-actions>
							<v-spacer></v-spacer>
							<v-btn color="indigo darken-1" text @click="dialog = false">Cancel</v-btn>
							<v-btn v-if="currentAction === 'create'" color="indigo darken-1" text @click="createUser">Create</v-btn>
							<v-btn v-if="currentAction === 'update'" color="indigo darken-1" text @click="updateUser">Update</v-btn>
						</v-card-actions>
					</v-card>
				</v-dialog>
				<v-btn color="indigo darken-1" dark @click="openDailog"> Add User </v-btn>
			</v-card-title>
			<v-data-table
				:headers="headers"
				:items="users"
				:items-per-page="10"
				class="elevation-2"
			>
				<template v-slot:[`item.actions`]="{ item }">
					<v-icon small class="mr-2" @click="editUser(item)">mdi-pencil</v-icon>
					<v-icon small @click="deleteUser(item.id)">mdi-delete</v-icon>
				</template>
			</v-data-table>
		</v-card>
	<v-snackbar v-model="snackbar">
		{{ message }}
		<template v-slot:action="{ attrs }">
			<v-btn color="white" text v-bind="attrs" @click="snackbar = false">Close</v-btn>
		</template>
	</v-snackbar>
	</v-container>
</template>
<script>
import { mapGetters } from "vuex";
export default {
	name: "UsersList",
	data() {
		return {
		dialog: false,
		user: [],
		currentAction: "",
		snackbar: false,
		message: "",
		headers: [
			{ text: "Name", value: "name" },
			{ text: "Email", value: "email" },
			{ text: "Mobile", value: "mobile" },
			{ text: "Country", value: "country" },
			{ text: 'Actions', value: 'actions', sortable: false }
		],
		};
	},
	components: {},
	props: {
		msg: String,
	},
	computed:{
        ...mapGetters({
            users: "getUsers",
            roles: "getRoles"
        })
    },
	methods: {
		openDailog () {
			this.user = [];
			this.currentAction = 'create';
			this.dialog = true;
		},
		createUser () {
			this.$store.commit('incrementUserId');
			this.$store.commit("createUser", Object.assign({}, this.user));
			this.dialog = false;
			this.message = "User created successfully"
			this.snackbar = true;
			this.user = [];
		},
		editUser (user) {
			this.currentAction = 'update';
			this.dialog = true;
            const currentRole = user.role;
            user.role = this.roles.find(role => role.id === currentRole);
			this.user = Object.assign({}, user);
		},
		updateUser () {
			this.dialog = false;
			this.$store.commit('updateUser', this.user);
			this.message = "User updated successfully"
			this.snackbar = true;
		},
		deleteUser (userId) {
			this.$store.commit("deleteUser", userId);
			this.message = "User deleted successfully"
			this.snackbar = true;
		}
	}
};
</script>
