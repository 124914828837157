<template>
	<v-app>
		<v-tabs v-model="tab" centered background-color="indigo darken-1" dark>
			<v-tab href="#tab-1">Users</v-tab>
			<v-tab href="#tab-2">Roles</v-tab>
			<v-tab href="#tab-3">Permissions</v-tab>
			<v-tabs-items v-model="tab">
				<v-tab-item value="tab-1">
					<Users/>
				</v-tab-item>
				<v-tab-item value="tab-2">
					<Roles/>
				</v-tab-item>
				<v-tab-item value="tab-3">
					<Permissions/>
				</v-tab-item>
			</v-tabs-items>
		</v-tabs>
	</v-app>
</template>

<script>
// @ is an alias to /src
import Users from '@/components/UsersList.vue';
import Roles from '@/components/RolesList.vue';
import Permissions from '@/components/PermissionsList.vue';

export default {
  name: 'HomeView',
  data () {
		return {
			tab: null,
		};
	},
  components: {
    Users,
    Roles,
    Permissions
  }
}
</script>
