<template>
    <v-container>
        <v-card class="elevation-1">
            <v-card-title>
                <h3>Permissions</h3>
                <v-spacer></v-spacer>
                <v-dialog v-model="dialog" persistent max-width="600px">
                    <v-card>
                        <v-card-title>
                            <span
                                class="text-h5"
                                v-if="currentAction === 'update'"
                                >Update Permission</span
                            >
                        </v-card-title>
                        <v-card-text>
                            <v-container>
                                <v-row no-gutters>
                                    <v-col cols="12">
                                        <h3><span style="color: grey;">Role Name:</span> {{ role.name }}</h3>
                                    </v-col>
                                </v-row>
                                <v-row no-gutters>
                                <v-col cols="12" sm="2" md="2">
                                    <v-checkbox v-model="role.read" label="Read" color="red" hide-details></v-checkbox>
                                </v-col>
                                <v-col cols="12" sm="2" md="2">
                                    <v-checkbox v-model="role.write" label="Write" color="red" hide-details></v-checkbox>
                                </v-col>
                                <v-col cols="12" sm="2" md="2">
                                    <v-checkbox v-model="role.modify" label="Modify" color="red" hide-details></v-checkbox>
                                </v-col>

                                </v-row>
                            </v-container>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                color="indigo darken-1"
                                text
                                @click="dialog = false"
                            >
                                Cancel
                            </v-btn>
                            <v-btn
                                v-if="currentAction === 'update'"
                                color="indigo darken-1"
                                text
                                @click="updatePermission"
                            >
                                Update
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-card-title>
            <v-data-table
                :headers="headers"
                :items="roles"
                :items-per-page="10"
                class="elevation-2"
            >
                <template v-slot:[`item.actions`]="{ item }">
                    <v-icon small class="mr-2" @click="editPermission(item)">
                        mdi-pencil
                    </v-icon>
                </template>
            </v-data-table>
        </v-card>
        <v-snackbar v-model="snackbar">
            {{ message }}
            <template v-slot:action="{ attrs }">
                <v-btn
                    color="white"
                    text
                    v-bind="attrs"
                    @click="snackbar = false"
                    >Close</v-btn
                >
            </template>
        </v-snackbar>
    </v-container>
</template>
<script>
import { mapGetters } from "vuex";
export default {
    name: "PermissionsList",
    data() {
        return {
            dialog: false,
            snackbar: false,
            message: "",
            role: [],
            currentAction: "",
            headers: [
                { text: "Role", value: "name" },
                { text: "Read", value: "read" },
                { text: "Write", value: "write" },
                { text: "Modify", value: "modify" },
                { text: "Actions", value: "actions", sortable: false },
            ],
        };
    },
    components: {},
    props: {
        msg: String,
    },
    computed: {
        ...mapGetters({
            roles: "getRoles"
        }),
    },
    methods: {
        openDailog() {
			this.permission = [];
            this.currentAction = "create";
            this.dialog = true;
        },
        editPermission(role) {
            this.currentAction = "update";
            this.dialog = true;
            this.currentRoleId = role.id;
            this.role = Object.assign({}, role);
        },
        updatePermission() {
            this.dialog = false;
            this.$store.commit("updateRole", this.role);
            this.message = "Permissions updated successfully";
            this.snackbar = true;
        }
    },
};
</script>

